import '@outloud/web-ui/dist/outloud.css'

import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useState } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { useMount } from 'react-use'
import ErrorBoundary from '~/components/ErrorBoundary'
import Alerts from '~/components/layout/Alerts'
import Modals from '~/components/layout/Modals'
import Middleware from '~/components/Middleware'
import Splash from '~/components/utils/Splash'
import { GoogleTagManager } from '@next/third-parties/google'

import * as layouts from '~/layouts'
import config from '~/services/ConfigService'
import logger from '~/services/LoggerService'
import { useStore } from '~/stores'
import '~/styles/app.scss'

import { type Page } from '~/types/pages'
import { useTenant } from '~/hooks/tenant'
import { useUser } from '~/hooks/user'
import { useUserStore } from '~/stores/user'
import { globalApi } from '~/services/APIService'
import MaintenancePage from '~/pages/maintenance'

type Props = AppProps & {
  Component: Page
}

export default function MyApp({ Component, pageProps }: Props) {
  const [isReady, setIsReady] = useState(false)
  const [isMaintenance, setIsMaintenance] = useState(false)
  const { fetchTenant } = useTenant()
  const { fetchUser } = useUser()
  const fetchConfig = useStore((store) => store.fetchConfig)

  const layout = (Component.layout ?? 'default') as keyof typeof layouts
  const LayoutComponent = layouts[layout]

  const title = [Component.title, config.APP_NAME].filter((item) => item).join(' - ')

  const load = async () => {
    logger.debug('App loading')

    const maintenanceConfig = await globalApi.$get('maintenance')

    setIsMaintenance(!!maintenanceConfig.maintenance)
    if (!maintenanceConfig.maintenance) {
      await Promise.all([fetchConfig(), fetchUser()])
      if (useUserStore.getState().isLoggedIn) {
        await fetchTenant()
      }
    }

    setIsReady(true)

    logger.debug('App ready')
  }

  useMount(load)

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      {process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && (
        <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID} />
      )}

      {isMaintenance && <MaintenancePage />}

      {!isMaintenance && (
        <ErrorBoundary>
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={isReady ? 1 : 0}
              classNames="fade"
              addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
            >
              {isReady ? (
                <Middleware middleware={Component.middleware}>
                  <LayoutComponent>
                    <Component {...pageProps} />
                  </LayoutComponent>

                  <Modals />
                  <Alerts />
                </Middleware>
              ) : (
                <Splash />
              )}
            </CSSTransition>
          </SwitchTransition>
        </ErrorBoundary>
      )}
    </>
  )
}
